
  .container {
    width: 100%;
    height: 100%;
    position: absolute;
    text-align: center;
    padding-top: 20%;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 99999;
  }
  
  .image {
    object-fit: cover;
  }